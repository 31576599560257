import styled from "styled-components"
import overlay from "../../../assets/illustrations/about-overlay.svg"
import overlay2 from "../../../assets/illustrations/about-overlay-mobile.svg"
import overlay3 from "../../../assets/illustrations/about-overlay-tablet.svg"


export const Wrapper = styled.div`
  padding: 2rem 0 0 4rem;
  display: flex;
  background-image: url(${overlay});
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
  position: relative;
  top: 50px;

  @media (min-width: 1100px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    padding: 2rem;
    background-image: url(${overlay2});
  }
  
  @media (min-width: 681px) and (max-width: 960px) {
    background-image: url(${overlay3});
  }
`


export const TopContainer = styled.div`
  flex: 1;
  div {
    margin-left: 4rem;
  }

  h2 {
    font-size: 4rem;
    letter-spacing: 3px;
    line-height: 3rem;
    div {
      margin-left: 0;
    }
  }
  
  span:last-child {
    color: #416125;
  }
  img {
    margin-left: 3rem;

    @media (max-width: 680px) {
      margin: 2rem 0;
      width: 100%;
    }

    @media (min-width: 681px) and (max-width: 960px) {
      margin: 1rem 0 2rem;
      width: 80%;
    }
  }
`

export const BottomContainer = styled.div`
  flex: 1;
  color: #336A03;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    font-size: 2.5rem;
  }
  
  img {
    @media (max-width: 680px) {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
`

