import React from "react"
import {Link} from 'react-scroll'
import { Directions } from "react-floating-button-menu"
import { ArrowDown, Button, Container, FloatMenu, Paragraph } from "../../common"
import { Wrapper, IntroWrapper, Details, Thumbnail, Icon } from "./styles"
import { HandWriting } from "./HandWriting"

export const Intro = () => {
  return (
    <section id="introduction">
      <FloatMenu direction={Directions.Up}/>
      <Wrapper>
        <IntroWrapper as={Container}>
          <Details>
            <HandWriting />
            <h1>Those on our side; Ourselves, one’s own people; One of us.</h1>
            <Paragraph>
              <span>
                <b>Kakilang</b> - a one stop shop for your best bubble tea experience and authentic Taiwanese cuisine.
                <br />
              </span>
              #nowyoureoneofus
            </Paragraph>
            <Icon>
                <ArrowDown/>
            </Icon>
            <Link offset={-100} to="category"><Button>Explore more</Button></Link>
          </Details>
          <Thumbnail />
        </IntroWrapper>
      </Wrapper>
    </section>
  )
}
