import React from "react"
import Zoom from "react-reveal/Zoom"
import Fade from 'react-reveal/Fade';
import { Card, Icon, Container, Left, Right, Content, Wrapper, Links } from "./styles"
import GoogleMaps from "../../common/GoogleMap"
import cursor from "../../../assets/icons/cursor.svg"
import open from "../../../assets/icons/clock.svg"
import contact from "../../../assets/icons/chat.svg"
import social from "../../../assets/icons/people.svg"
import { SocialLink } from "../../Layout/Header/NavbarLinks/styles"
import { Facebook, Instagram } from "../../common"
import fb from "../../../assets/icons/facebook.svg"
import ig from "../../../assets/icons/instagram.svg"
import { graphql, useStaticQuery } from "gatsby"


export const Contact = () => {
  const data = useStaticQuery(
    graphql`
        query {
            contentfulShopInformation {
              email
              facebookLinkDesktop
              facebookLinkPhone
              instagramLink
              openingHours
              phone
              formatPhoneNumber
              address
              googleMap
            }
          }
    `
  )
  const { contentfulShopInformation } = data;
  return (
    <section id="contact">
      <Wrapper>
        <Right>
          <h2><Zoom cascade duration={1500}>We are here</Zoom></h2>
          <Container>
            <Card>
              <Icon>
                <img src={cursor} alt="shop address" />
              </Icon>
              <Content>
                <Fade bottom duration={1000}><h3>Location</h3></Fade>
                <Fade bottom delay={300} duration={1000}>
                  <a target="_blank" href={contentfulShopInformation.googleMap}>{contentfulShopInformation.address}</a>
                </Fade>
              </Content>
            </Card>
            <Card>
              <Icon>
                <img src={contact} alt="contact icon" />
              </Icon>
              <Content>
                <Fade bottom duration={1000}><h3>Contact Us</h3></Fade>
                <Fade bottom delay={300} duration={1000}><a target="_blank" href={"tel:" + contentfulShopInformation.phone}>{contentfulShopInformation.formatPhoneNumber}</a></Fade>
                <Fade bottom delay={600} duration={1000}><a target="_blank" href="mailto:hello@kakilang.ie">{contentfulShopInformation.email}</a></Fade>
              </Content>
            </Card>
          </Container>
          <Container>
            <Card>
              <Icon>
                <img src={open} alt="Opening hours icons" />
              </Icon>
              <Content>
                <Fade bottom duration={1000}><h3>Opening Hours</h3></Fade>
                <Fade bottom delay={300} duration={1000}><p>{contentfulShopInformation.openingHours}</p></Fade>
              </Content>
            </Card>
            <Card>
              <Icon>
                <img src={social} alt="social media icons" />
              </Icon>
              <Content>
                <Fade bottom duration={1000}><h3>Social Media</h3></Fade>
                <Links>
                  <Fade bottom delay={300} duration={1000}><SocialLink href={contentfulShopInformation.facebookLinkDesktop} target="_blank" rel="noopener noreferrer"><Facebook
                    src={fb} alt="Facebook icon" /></SocialLink></Fade>
                  <Fade bottom delay={600} duration={1000}><SocialLink href={contentfulShopInformation.instagramLink} target="_blank" rel="noopener noreferrer"><Instagram
                    src={ig} alt="Instagram icon" /></SocialLink></Fade>
                </Links>
              </Content>
            </Card>
          </Container>
        </Right>
        <Left>
          <GoogleMaps />
        </Left>
      </Wrapper>
    </section>
  )
}
