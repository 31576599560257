import React from "react"
import styled from "styled-components"
import Zoom from "react-reveal/Zoom"
import { Img } from '../../common'

export const Wrapper = styled.div`
  background-color: #ECEAE0;
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem 0 0;
  position: relative;
  top: 80px;

  @media (max-width: 960px) {
    display: block;
  }
`


export const Left = styled.div`
  flex: 1;
  @media (max-width: 680px) {
    display: block;
    width: 100%;
  }
`

export const Right = styled.div`
  flex: 1;
  padding: 1rem;

  @media (max-width: 680px) {
    display: block;
    width: 100%;
    padding: 0;
  }

  @media (min-width: 1100px) {
    margin: 5rem 0;
  }

  h2 {
    font-size: 4rem;
    margin: 2rem 0 5rem;
    text-align: center;
    letter-spacing: 3px;
    color: #4e4e4e;

    @media (max-width: 680px) {
      padding: 2rem;
      margin: 0;
    }
    @media (min-width: 1100px) {
      margin: 0 0 5rem;
    }
  }
`

export const Container = styled.div`
  display: flex;
  color: #4e4e4e;
  justify-content: space-around;
  margin: 0 3rem 3rem;

  @media (max-width: 680px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

export const Icon = ({children}) => (
  <Zoom>
    <Img>{children}</Img>
  </Zoom>
)

export const Icon2 = ({children}) => (
  <Img>{children}</Img>
)


export const Content = styled.div`
  flex: 1;
  margin-left: 1rem;
  color: #4e4e4e;
  h3 {
    line-height: 40px;
    margin-bottom: 0.5rem;
    font-weight: normal;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  a {
    line-height: 30px;
    margin-bottom: 5px;
    text-decoration: underline;
    font-size: 1.25rem;
    letter-spacing: 0.8px;
    font-weight: 100;
    display: block;
    //font-family: "Anonymous Pro", sans-serif;
    :hover {
      color: #efaa7c;
    }
  }

  p {
    line-height: 30px;
    margin-bottom: 0;
    font-size: 1.2rem;
    letter-spacing: 0.8px;
    font-weight: 100;
    //font-family: "Anonymous Pro", sans-serif;
  }

  span {
    font-weight: bold;
  }
`


export const Card = styled.div`
  margin: 0;
  display: flex;
  flex: 1;

  @media (max-width: 680px) {
    padding: 0;
    margin: 2rem 0.5rem;
  }

  @media (min-width: 1200px) {
    padding: 0 2rem;
  }
`


export const Links = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`
