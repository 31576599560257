import React from "react"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import { BottomContainer, TopContainer, Wrapper } from "./styles"
import shop1 from "../../../assets/images/shop1.jpg"
import shop2 from "../../../assets/images/shop2.jpg"
import { Paragraph } from "../../common"


export const About = () => {
  return (
    <section id="about">
      <Wrapper>
        <TopContainer>
          <h2><Zoom cascade>About <span>Kakilang</span></Zoom></h2>
          <Paragraph>
            Here at Kakilang, established in 2020, our mission is to bring the bubble tea culture into the hearts of the Irish. Located in the centre of Dublin, we serve Taiwanese tea-based drinks and some unique asian street food. Harmonising between sweet and savoury.
          </Paragraph>
          <Fade up><img src={shop2} alt="shop view2" width="60%" loading="lazy" /></Fade>
        </TopContainer>
        <BottomContainer>
          <Fade up><img src={shop1} alt="shop view" width="80%" loading="lazy" /></Fade>
          <Fade up><div>
            <h3>Our team</h3>
            <Paragraph>
              We are a multicultural team, and as cheesy as it sounds, together we bring the world as one. We are a family, come into Kakilang and you’ll feel just like home.
            </Paragraph>
          </div></Fade>
        </BottomContainer>
      </Wrapper>
    </section>
  )
}
