import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from "react-responsive/src"
import { Flag, PostImage, PostLink, Posts, Wrapper } from "./styles"
import { Text, Title } from "../../common"
import play from '../../../assets/icons/play.svg'
import slide from '../../../assets/icons/slide.svg'
import config from "../../../data/config"




export const Gallery = () => {
  const NUM_POSTS = 10;
  const data = useStaticQuery(
    graphql`
      query {
        allInstagramContent(sort: {order: DESC, fields: timestamp}, limit: 10) {
          nodes {
              id
              caption
              media_type
              permalink
              localImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    `
  )
  const isLargeDevice = useMediaQuery({
    query: "(min-device-width: 769px)"
  })

  let n = 0;
  return (
    <section id="gallery">
      <Wrapper>
        <a href={config.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
          {isLargeDevice ? <Text>#kakilang</Text> : <Title>#kakilang</Title>}
        </a>
      </Wrapper>
      <Posts>
        { data.allInstagramContent.nodes.map((node) => {
          if (n++ < NUM_POSTS) {
            const id = node.id
            const caption = node.caption
            const img = node.localImage.childImageSharp.fluid
            const video = node.mediaType === "GraphVideo"
            const sidecar = node.mediaType === "GraphSidecar"
            const permalink = node.permalink
            return (
              <PostLink href={permalink} target="_blank" rel="noopener noreferrer" key={id}>
                {(video || sidecar)&&<Flag src={video ? play : sidecar ? slide : ""}/>}
                <PostImage fluid={img} alt={caption} key={id} id="PostImage" />
              </PostLink>
            )
          }
        })}
      </Posts>
    </section>
  )
}
