import styled from "styled-components"
import React from "react"
import Img from "gatsby-image"

export const Wrapper = styled.div`
  margin-top: 5rem;
  padding: 0 0 5rem;
  background-color: white;
  
  @media (min-width: 1500px) {
    width: 80%;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 3.5rem;
    margin: 2rem 0 5rem;
    text-align: center;
    letter-spacing: 3px;
    color: #4e4e4e;
    @media (max-width: 680px) {
      margin: 2rem 0 2rem;
    }
  }
`

export const Container = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  
  @media (max-width: 960px) {
    display: block;
  }
`
export const Product = styled.div`
  flex:1;
  margin: 0 2rem;
  @media (max-width: 960px) {
    margin: 0 auto;
    width: 70%;
    text-align: center;
  }

  @media (min-width: 681px) and (max-width: 960px) {
    margin: 0 auto 2rem;
    width: 40%;
    text-align: center;
  }

  span {
    display: block;
    font-weight: bolder;
    letter-spacing: 0.1rem;
    font-size: 1.7rem;
    line-height: 1.7rem;
    margin-bottom: 0.7rem;
    color: #4e4e4e;
  }

  p {
    color: #4e4e4e;
    font-size: 1.3rem;
    letter-spacing: 0.8px;
    opacity: 0.8;
    font-weight: 100;
  }
`

export const ButtonContainer = styled.div`
  text-align: center;
  button {
    font-size: 1.7rem;
  }
`

export const Note = styled.span`
  font-size: 1rem !important;
`

export const Title = styled.h2`
  margin: 0 0 2rem;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
  padding: 0 16px;
  justify-content: center;
`

export const Image = styled(Img)`
  margin-bottom: 1rem;
  img {
    border-radius: 50%;
  }
`
