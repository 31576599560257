import React from "react"
import { SEO } from "../components/common"
import { Intro, Category, About, Contact, Gallery } from "../components/landing"
import { Header, Base } from "../components/Layout"


const IndexPage = () => (
  <Base overlay={false}>
    <SEO title="Kakilang - Bubble tea & Asian street food."
         description="See the world of the asians. Our unique Taiwanese, Korean, Japanese etc. food & beverage offerings. Explore Kakilang and be WOWed." />
    <Header />
    <Intro />
    <Category />
    <About />
    <Contact />
    <Gallery />
  </Base>
)

export default IndexPage
