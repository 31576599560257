import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image'

export const Wrapper = styled.div`
  margin: 8rem 0 1rem;

  h2 {
    text-transform: lowercase;
    width: 20%;
    margin: 0 auto;
    font-size: 2.5rem;
    font-weight: 100;
  }

  h2:hover {
    color: #efaa7c;
  }
  
  @media (max-width: 680px) {
    h2 {
      width: 80%;
    }
  } 

`;

export const Posts = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
`

export const PostLink = styled.a`
  width: 20%;
  position: relative;

  @media (max-width: 960px) {
    width: 50%;
  }
  
  :hover {
    opacity: 50%;
  }
`

export const PostImage = styled(BackgroundImage)`
  height: 20vw;
  width: 100%;
  background-size: cover;
  background-position: center center;
  @media (max-width: 960px) {
    height: 50vw;
  }
`

export const Flag = styled.img`
  width: 23px;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 999;
  background-color: hsla(0,0%,0%,0.3);
  padding: 0.5rem;
`;
