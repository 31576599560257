import styled, { keyframes } from "styled-components"
import { zoomIn } from "react-animations"
import kl from "../../../assets/illustrations/kl.svg"

export const Wrapper = styled.div`
  display: flex;
  background: linear-gradient(to right, #ECEAE0 60%, white 0%);
  height: 90vh;

  @media (max-width: 960px) {
    background: rgb(236 234 224 / 50%);
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
  }
`

export const IntroWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column-reverse;
  }
`

export const Details = styled.div`
  flex: 1;

  @media (max-width: 1100px) {
    width: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    margin: 1rem 0 2rem;
    font-size: 45px;
    //font-family: Transat, sans-serif;
    letter-spacing: 3px;
    font-weight: bold;
    max-height: 104px;
    //background-color: #3f6b6b;

    @media (max-width: 680px) {
      margin: 0 auto;
      letter-spacing: 1px;
      font-size: 40px;
      text-align: center;
      padding-bottom: 1rem;
      width: 95%;
    }

    @media (max-height: 568px) {
      font-size: 30px;
    }

    @media (min-width: 681px) and (max-width: 960px) {
      font-size: 40px;
      padding: 1rem 0;
    }

    @media (max-width: 380px) {
      font-size: 30px;
    }
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 1.5rem !important;
    max-height: 72px;
    @media (max-width: 680px) {
      font-size: 1.3rem !important;
      text-align: center;
      width: 90%;
      margin: 0 auto;
    }
  }
  
  button {
    @media (max-width: 680px) {
      display: none;
    }
  }
`
const zoomInAnimation = keyframes`${zoomIn}`;

export const Thumbnail = styled.div`
  flex: 0.8;
  display: block;
  margin: 2rem 0 1rem;
  text-align: center;
  height: 90%;
  background-image: url(${kl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 960px) {
    animation: 2s ${zoomInAnimation};
  }
  @media (max-width: 960px) {
    flex: 1;
    width: 100%;
    margin: 2rem 0 0;
  }

  @media (max-width: 680px) {
    margin: 0;
  }
`


export const Icon = styled.div`
  width: 40px;
  height: 40px;
  margin: 10px auto 0;

  @media (min-width: 680px) {
    display: none;
  }
`

export const Float = styled.div`
  position: fixed;
  top: 80px;
  right: 20px;
`


export const MySVG = styled.svg`
  @keyframes letterDraw {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  width: 100px;
  height: 100px;

  @media (max-width: 680px) {
    transform: scale(0.7);
  }

  .line {
    fill: none;
    stroke-width: 150;
    stroke: #4e4e4e;
    stroke-linecap: round;
    stroke-linejoin: miter;
    transform: translate(0, 87);
  }

  .line .line-1 {
    stroke-dashoffset: 1617;
    stroke-dasharray: 1617;
    animation: letterDraw 1s linear .5s forwards;
  }

  .line .line-2 {
    stroke-dashoffset: 798;
    stroke-dasharray: 798;
    animation: letterDraw 1s linear 1s forwards;
  }

  .line .line-3 {
    stroke-dasharray: 2190;
    stroke-dashoffset: 2190;
    animation: letterDraw 1s linear 1.5s forwards;
  }

  .line .line-4 {
    stroke-dashoffset: 842;
    stroke-dasharray: 842;
    animation: letterDraw 1s linear 2s forwards;
  }

  .line .line-5 {
    stroke-dashoffset: 892;
    stroke-dasharray: 892;
    animation: letterDraw 1s linear 2.5s forwards;
  }

  .line .line-6 {
    stroke-dashoffset: 571;
    stroke-dasharray: 571;
    animation: letterDraw .5s linear 3s forwards;
  }

  .line .line-7 {
    stroke-dashoffset: 680;
    stroke-dasharray: 680;
    animation: letterDraw .5s linear 3.5s forwards;
  }

  .line .line-8 {
    stroke-dashoffset: 422;
    stroke-dasharray: 422;
    animation: letterDraw .5s linear 4s forwards;
  }

  .line .line-9 {
    stroke-dashoffset: 1153;
    stroke-dasharray: 1153;
    animation: letterDraw .5s linear 4.5s forwards;
  }

  .line .line-10 {
    stroke-dasharray: 920;
    stroke-dashoffset: 920;
    animation: letterDraw .5s linear 5s forwards;
  }

  .line .line-11 {
    stroke-dashoffset: 780;
    stroke-dasharray: 780;
    animation: letterDraw .5s linear 5.5s forwards;
  }

`

export const SVGWrapper = styled.div`
  display: flex;

  @media (max-width: 680px) {
    justify-content: center;
  }
`

export const Container = styled.div`
  width: 100px;
`
