import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Zoom from "react-reveal/Zoom"
import { useMediaQuery } from "react-responsive/src"
import { Wrapper, Container, Product, ButtonContainer, Note, Image, Title } from "./styles"
import { Button } from "../../common"


export const Category = () => {
  const data = useStaticQuery(
    graphql`
        query {
          cake: file(relativePath: {eq: "Crepe_Cake_2.png"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          chicken: file(relativePath: {eq: "Chicken.jpg"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          takoyaki: file(relativePath: {eq: "Takoyaki.jpg"}) {
             childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bb_tea: file(relativePath: {eq: "bb_tea.jpg"}) {
             childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          pancakes: file(relativePath: {eq: "Pancake.jpg"}) {
             childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
    `
  )
  const isLargeDevice = useMediaQuery({
    query: "(min-device-width: 769px)"
  })
  return (
    <section id="category">
      <Wrapper>
        <Title>
          {isLargeDevice ?
            <Zoom cascade duration={1500}>
              Our unique offerings
            </Zoom> :
            <Zoom cascade duration={1500}>
              Our unique <span>offerings</span>
            </Zoom>
          }
        </Title>
        <Container>
          {/*<Product><Zoom duration={1200}><Image fluid={data.cake.childImageSharp.fluid} alt="Mille Crepes Cake" /></Zoom>*/}
          {/*  <span>Mille Crepes Cake</span>*/}
          {/*  <p>*/}
          {/*    A classic French pastry - with Mille meaning ‘thousands’ hence the chinese name "Thousand Layer Cake" <Note> - 千層蛋糕.</Note>*/}
          {/*  </p>*/}
          {/*</Product>*/}
          <Product><Zoom duration={1200} delay={isLargeDevice ? 500 : 0}><Image
            fluid={data.takoyaki.childImageSharp.fluid} alt="Takoyaki" /></Zoom><span>Takoyaki</span>
            <p>
              A Japanese street food - “octopus ball” is a batter filled with pieces of
              octopus, cabbage and scallions cooked in a cast iron shaped into a ball.
            </p></Product>
          <Product><Zoom duration={1200} delay={isLargeDevice ? 800 : 0}><Image
            fluid={data.bb_tea.childImageSharp.fluid} alt="Bubble tea" /></Zoom><span>Bubble Tea</span>
            <p>
              Unique flavoured drinks infused with tea, topped with chewy tapioca balls/pearls. It comes with a wide
              variety of flavours.
            </p></Product>
          <Product><Zoom duration={1200} delay={isLargeDevice ? 1100 : 0}><Image
            fluid={data.chicken.childImageSharp.fluid} alt="Taiwanese Fried Chicken steak" /></Zoom><span>Taiwanese Fried Chicken Steak</span>
            <p>
              A Taiwanese street food - butterflied chicken breast coated in a light
              batter and unique salt and chilli taiwanese spice seasoning.
            </p></Product>
          <Product><Zoom duration={1200} delay={isLargeDevice ? 1400 : 0}><Image
            fluid={data.pancakes.childImageSharp.fluid} alt="Japanese Souffle Pancakes" /></Zoom><span>Japanese Souffle Pancake</span>
            <p>
              A Japanese style soft and jiggly, fluffy pancake like a soufflé.
            </p></Product>
        </Container>
        <ButtonContainer>
          <Zoom><Link to="/menu"><Button>Our Menu</Button></Link></Zoom>
        </ButtonContainer>
      </Wrapper>
    </section>
  )
}
